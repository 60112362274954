import './bootstrap';
import '../scss/app.scss'
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

import $ from 'jquery'
window.jQuery = window.$ = $

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
window.Swal = Swal;

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

/* import IMask from 'imask';
window.IMask = IMask;

var numberMask = IMask(
    document.getElementById('input_price'),
    {
      mask: Number,
      thousandsSeparator: '.',
      radix: ',',
      scale: 2,
      max: 1000000000
    });
 */
import Chart from 'chart.js/auto';

window.Chart = Chart;

const charts = document.querySelector('#main-bar-chart');
if (document.getElementById('main-bar-chart')) {
    const ctx = document.getElementById('main-bar-chart').getContext('2d');

    const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: JSON.parse(charts.dataset.labels),
            datasets: [{
                label: 'Entrate',
                data: JSON.parse(charts.dataset.entrate),
                backgroundColor: [
                    '#D6FFDC'

                ],
                borderColor: [
                    '#D6FFDC'

                ],
                borderWidth: 1
            }, {
                label: 'Uscite',
                data: JSON.parse(charts.dataset.uscite),
                backgroundColor: [
                    '#F7B8B6'
                ],
                borderColor: [
                    '#F7B8B6'
                ],
                borderWidth: 1
            }

            ]
        },
        options: {

            borderRadius: 10,
            interaction: {
                intersect: false,
                mode: 'index',
            },

            responsive: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(context.parsed.y);
                            }
                            return label;
                        }
                    }
                }
            },

            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    beginAtZero: true,
                    stepSize: 1,
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                            return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
                        }
                    }
                }
            },

        }
    });
}

if (document.getElementById('doughnut')) {
    const doughnut = document.getElementById('doughnut');

    let entrate = doughnut.dataset.entrate;
    let uscite = doughnut.dataset.uscite;

    const myDoughnut = new Chart(doughnut, {

        type: 'doughnut',
        data: {
            labels: ['Uscite', 'Entrate'],
            datasets: [{
                data: [uscite,entrate],
                backgroundColor: ['#db3700', '#0d9488'],
                hoverBackgroundColor: ['#db3700', '#0d9488'],
                borderWidth: 1,
                radius: 75,
                cutout: 50,
            }]
        },
        options: {
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    });
}
if (document.getElementById('doughnut-balance')) {
    const doughnut = document.getElementById('doughnut-balance');

    let entrate = doughnut.dataset.entrate;
    let uscite = doughnut.dataset.uscite;

    const myDoughnut = new Chart(doughnut, {

        type: 'doughnut',
        data: {
            labels: ['Uscite', 'Entrate'],
            datasets: [{
                data: [uscite,entrate],
                backgroundColor: ['#db3700', '#0d9488'],
                hoverBackgroundColor: ['#db3700', '#0d9488'],
                borderWidth: 1,
                radius: 75,
                cutout: 50,
            }]
        },
        options: {
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    });
}

window.addEventListener('modal',function(e){

        const container = document.getElementById(e.detail.idModal);
        const modal = bootstrap.Modal.getInstance(container)

        Swal.fire(e.detail).then((result) => {
            if (result.isConfirmed) {
                if(e.detail.refresh)
                {
                    location.reload();
                    modal.hide();
                }

            }
          });

});

if (document.getElementById('select_categoria')) {
const select = document.getElementById('select_categoria');
const box = document.getElementById('details_donation');

select.addEventListener('change', function handleChange(event) {

    if(event.target.value == 'donazione')
    {
        box.classList.remove("d-none");
        box.classList.add("d-block");
    }else{
        box.classList.add("d-none");
        box.classList.remove("d-block");
    }

  console.log(event.target.value); // 👉️ get selected VALUE

  // 👇️ get selected VALUE even outside event handler
  console.log(select.options[select.selectedIndex].value);

  // 👇️ get selected TEXT in or outside event handler
  console.log(select.options[select.selectedIndex].text);
});
}
if (document.querySelectorAll('input[type=radio][name="reimbursement"]')) {
    const radioButton = document.querySelectorAll('input[type=radio][name="reimbursement"]');
    const wrapper_rimborso = document.getElementById('wrapper_rimborso');

    radioButton.forEach(radio => radio.addEventListener('change', () =>{
        if(radio.value == 1)
        {
            wrapper_rimborso.classList.remove("d-none");
            wrapper_rimborso.classList.add("d-block");
        }else{
            wrapper_rimborso.classList.add("d-none");
            wrapper_rimborso.classList.remove("d-block");
        }
    }));
}

if (document.querySelectorAll('input[type=radio][name="pay"]')) {
    const radioButton = document.querySelectorAll('input[type=radio][name="pay"]');
    const wrapper_pagamento = document.getElementById('wrapper_pagamento');

    radioButton.forEach(radio => radio.addEventListener('change', () =>{
        if(radio.value == 1)
        {
            wrapper_pagamento.classList.remove("d-none");
            wrapper_pagamento.classList.add("d-block");
        }else{
            wrapper_pagamento.classList.add("d-none");
            wrapper_pagamento.classList.remove("d-block");
        }
    }));
}

if (document.querySelectorAll('input[type=radio][name="reimbursement"]')) {
    const radioButton = document.querySelectorAll('input[type=radio][name="reimbursement"]');
    const radioButtonPay = document.querySelectorAll('input[type=radio][name="pay"]');

    const wrapper_pagamento = document.getElementById('wrapper_pagamento');

    radioButton.forEach(radio => radio.addEventListener('change', () =>{
        if(radio.value == 1)
        {

            document.getElementById("pay1").checked = true;
            document.getElementById("pay1").disabled = true;  
            document.getElementById("pay2").disabled = true;  
   

            wrapper_pagamento.classList.remove("d-none");
            wrapper_pagamento.classList.add("d-block");
        }else{
            wrapper_pagamento.classList.add("d-none");
            wrapper_pagamento.classList.remove("d-block");

            document.getElementById("pay1").checked = false;
            document.getElementById("pay2").checked = true;
            document.getElementById("pay1").disabled = false;  
            document.getElementById("pay2").disabled = false; 
        }
    }));
}






